<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Input</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              A text input field is a basic component of form used for filling a
              single piece of information.
            </p>
          </div>
        </section>

        <div class="divider w1200"></div>

        <section id="general-guidelines">
          <div class="content-band">
            <h2>General Guidelines</h2>
            <ul class="bullet-red">
              <li>
                A text input field is for filling in one piece of information,
                in general no more than 3 words. Any more than 3 should use a
                text area.
              </li>
              <li>
                All fields should be required by default, only fields that are
                not required or optional are indicated with the (optional) note
                in the label.
              </li>
            </ul>
          </div>
        </section>

        <div class="divider"></div>

        <section id="primary-button">
          <div class="content-band">
            <h3>Text Input</h3>
            <CodeView :component="primary.main">
              <TextInputComponent type="main" placeholder="Label Name" />
            </CodeView>
            <CodeView :component="primary.white" class="codeview--gray">
              <TextInputComponent type="white" placeholder="Label Name" />
            </CodeView>
            <!-- <CodeView :component="primary.optional">
            <TextInputComponent
              type="optional"
              placeholder="Label Name (optional)"
            />
          </CodeView> -->
            <CodeView :component="primary.hint">
              <TextInputComponent
                type="hint"
                placeholder="Label Name (optional)"
              />
            </CodeView>
            <CodeView :component="primary.error">
              <TextInputComponent
                type="error"
                placeholder="Label Name (optional)"
              />
            </CodeView>
            <!-- <CodeView :component="primary.combo">
            <TextInputComponent type="combo" placeholder="Label Name" />
          </CodeView> -->
          </div>
        </section>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
// import StickyTOC from "../StickyTOC";
import TextInputComponent from "../TextInputComponent";
import CodeView from "../CodeView";
import TempLeftNav from "../TempLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "Input",
  data: function() {
    return {
      primary: {
        main: {
          codeHTML: "<input type='text' placeholder='Label Name' />",
          codeCSS: `           input { 
                                border: none;
                                background-color: #f5f5f5;
                                width: 280px;
                                height: 50px;
                                border-top-left-radius: 4px;
                                border-top-right-radius: 4px;
                                border-bottom: 2px solid #0080d9;
                                padding: 10px;
                                font-size: 1rem;
                    }`,
          codeJS: "// No JS Needed",
          preview: "This is the preview",
          name: "Text Input Field and Label - Gray(default)",
        },
        white: {
          codeHTML:
            "<input type='text' placeholder='Label Name' class='white'/>",
          codeCSS: `           input.white { 
                                border: none;
                                background-color: white;
                                width: 280px;
                                height: 50px;
                                border-top-left-radius: 4px;
                                border-top-right-radius: 4px;
                                border-bottom: 2px solid #0080d9;
                                padding: 10px;
                                font-size: 1rem;
                    }`,
          codeJS: "// No JS Needed",
          preview: "This is the preview",
          name: "Text Input Field and Label - White",
        },
        optional: {
          codeHTML: "<input type='text' placeholder='Label Name' />",
          codeCSS: `           input { 
                                border: none;
                                background-color: #f5f5f5;
                                width: 280px;
                                height: 50px;
                                border-top-left-radius: 4px;
                                border-top-right-radius: 4px;
                                border-bottom: 2px solid #0080d9;
                                padding: 10px;
                                font-size: 1rem;
                    }`,
          codeJS: "// No JS Needed",
          preview: "This is the preview",
          name: "Text Input Field and Label (Optional pattern)",
        },
        hint: {
          codeHTML: `<input type='text' placeholder='Label Name' class='hint' />
                    <div class="hint__container">Sample Hint</div>`,
          codeCSS: `           input { 
                                border: none;
                                background-color: #f5f5f5;
                                width: 280px;
                                height: 50px;
                                border-top-left-radius: 4px;
                                border-top-right-radius: 4px;
                                border-bottom: 2px solid #0080d9;
                                padding: 10px;
                                font-size: 1rem;
                    }
                    .hint__container {
                    width: 280px;
                    margin: 0 auto;
                    text-align: left;
                    font-size: 14px;
                  }`,
          codeJS: "// No JS Needed",
          preview: "This is the preview",
          name: "Text Input Field with Hint",
        },
        combo: {
          codeHTML: "<input type='text' placeholder='Label Name' />",
          codeCSS: `           input { 
                                border: none;
                                background-color: #f5f5f5;
                                width: 280px;
                                height: 50px;
                                border-top-left-radius: 4px;
                                border-top-right-radius: 4px;
                                border-bottom: 2px solid #0080d9;
                                padding: 10px;
                                font-size: 1rem;
                    }`,
          codeJS: "// No JS Needed",
          preview: "This is the preview",
          name: "Text Input Field with Value",
        },
        error: {
          codeHTML: "<input type='text' placeholder='Label Name' />",
          codeCSS: `           input { 
                                border: none;
                                background-color: #f5f5f5;
                                width: 280px;
                                height: 50px;
                                border-top-left-radius: 4px;
                                border-top-right-radius: 4px;
                                border-bottom: 2px solid #0080d9;
                                padding: 10px;
                                font-size: 1rem;
                    }`,
          codeJS: "// No JS Needed",
          preview: "This is the preview",
          name: "Text Input Field with error",
        },
      },
    };
  },
  components: {
    CodeView,
    TextInputComponent,
    TempLeftNav,
    SubFooter,
    // StickyTOC,
  },
};
</script>
