var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input__container"},[_c('input',{class:{
      main: _vm.type === 'main',
      white: _vm.type === 'white',
      optional: _vm.type === 'optional',
      hint: _vm.type === 'hint',
      error: _vm.type === 'error',
      combo: _vm.type === 'combo',
    },attrs:{"type":"text","placeholder":_vm.placeholder}}),(_vm.type === 'hint')?_c('div',{staticClass:"hint__container"},[_vm._v("Sample Hint")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }