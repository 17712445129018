<template>
  <div class="input__container">
    <input
      type="text"
      :placeholder="placeholder"
      :class="{
        main: type === 'main',
        white: type === 'white',
        optional: type === 'optional',
        hint: type === 'hint',
        error: type === 'error',
        combo: type === 'combo',
      }"
    />
    <div v-if="type === 'hint'" class="hint__container">Sample Hint</div>
  </div>
</template>

<script>
export default {
  name: "TextInputComponent",
  props: ["type", "placeholder"],
};
</script>

<style scoped>
input {
  border: none;
  background-color: #f5f5f5;
  width: 280px;
  height: 50px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 2px solid #8c8c8c;
  padding: 10px;
  font-size: 1rem;
}
input.white {
  background-color: white;
}
input.error {
  border-bottom-color: #ef1217;
}
.hint__container {
  width: 280px;
  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  position: relative;
  left: 15px;
}
.hint__container::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #4d4d4d;
  top: 7px;
  left: -13px;
}
.input__container {
  text-align: center;
}
::placeholder {
  color: #2a2a2a;
}
</style>
